import axios from "axios";
import React, { useState } from "react";
import AuthService from "services/auth.service";
import CheckmarkIcon from "../../../assets/icons/checkmarkIcon";
import { useSwipeable } from "react-swipeable";
import { BASEURL } from "../../../config/config";
import { NavLink } from "react-router-dom";

export default function Subscription() {
  const currentUser = AuthService.getCurrentUser();

  
  const [selectedPlan, setSelectedPlan] = useState("yearly");

  const checkout = (plan, userId) => {
    axios
      .post(
        `${BASEURL}/api/payment/create-subscription-checkout-session`,
        {
          plan: plan,
          customerId: userId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "x-access-token": currentUser.accessToken,
          },
        }
      )
      .then((res) => {
        window.location = res.data.session.url;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSwipe = (direction) => {
    const plans = ["yearly", "quarterly", "monthly"];
    const currentIndex = plans.indexOf(selectedPlan);
    let newIndex = currentIndex;

    if (direction === "left") {
      newIndex = (currentIndex + 1) % plans.length;
    } else if (direction === "right") {
      newIndex = (currentIndex - 1 + plans.length) % plans.length;
    }

    setSelectedPlan(plans[newIndex]);
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => handleSwipe("left"),
    onSwipedRight: () => handleSwipe("right"),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  const getSubscriptionDetails = () => {
    switch (selectedPlan) {
      case "yearly":
        return {
          price: "$7.99",
          period: "($95.88 <br> per year)",
          title: "Annual (Most Popular)",
        };
      case "quarterly":
        return {
          price: "$9.99",
          period: "($29.97 every <br> 3 months)",
          title: "3 Months",
        };
      case "monthly":
        return {
          price: "$19.99",
          period: "per month",
          title: "Monthly Plan",
        };
      default:
        return {
          price: "$19.99",
          period: "per month",
          title: "Monthly Plan",
        };
    }
  };

  const { price, period, title } = getSubscriptionDetails();

  return (
    <div
      {...handlers}
      className="w-[400px] rounded-xl relative bg-red h-screen "
      style={{ fontFamily: "poppins" }}
    >
      <div
        className="flex justify-between rounded-xl mb-4"
        style={{
          background: "#FF90A1",
          borderRadius: "28.53px",
          padding: "8px",
        }}
      >
        <button
          onClick={() => setSelectedPlan("yearly")}
          className={`${
            selectedPlan === "yearly"
              ? "bg-purple-600 text-white"
              : "text-[rgba(1,1,1,0.6)]"
          } p-3 rounded-full text-[16px] font-semibold max-w-fit`}
        >
          <span className="p-3">Yearly</span>
        </button>
        <button
          onClick={() => setSelectedPlan("quarterly")}
          className={`${
            selectedPlan === "quarterly"
              ? "bg-purple-600 text-white"
              : "text-[rgba(1,1,1,0.6)]"
          } p-3 rounded-full text-[16px] font-semibold max-w-fit`}
        >
          <span className="p-3">Quarterly</span>
        </button>
        <button
          onClick={() => setSelectedPlan("monthly")}
          className={`${
            selectedPlan === "monthly"
              ? "bg-purple-600 text-white"
              : "text-[rgba(1,1,1,0.6)]"
          } p-3 rounded-full text-[16px] font-semibold max-w-fit`}
        >
          <span className="p-3">Monthly</span>
        </button>
      </div>
      <div className="h-[650px] overflow-auto scrollbar-hide relative"> 
        <div
          className="flex items-center absolute right-[0px] bg-white text-black px-4 py-2 text-[26px] rounded-xl z-30 "
          style={{
            clipPath: "polygon(0 0, 100% 0%, 100% 100%, 12% 100%)",
            border: "1px solid #f1edea",
          }}
        >
          <div className="mr-2 font-semibold">{price}</div>
          <div
            className="text-[12px] text-[#858585]"
            dangerouslySetInnerHTML={{ __html: period }}
          ></div>
        </div>

        <div
          className="w-[400px] mb-12 top-[16px] bg-[#fff8f5] rounded-xl shadow-lg p-4 relative border border-[#f1edea] z-20"
          style={{
            clipPath:
              "polygon(54% 0px, 59% 8%, 100% 8%, 100% 100%, 0px 100%, 0px 0px, 35% 0px)",
            border: "1px solid #f1edea",
          }}
        >
          <div
            className="bg-white rounded-xl border border-[#eae7e3] p-2 pl-4 "
            style={{
              clipPath:
                "polygon(54% 0px, 59% 8%, 100% 8%, 100% 100%, 0px 100%, 0px 0px, 35% 0px)",
              border: "1px solid #f1edea",
            }}
          >
            <div className="mt-6">
              <h1 className="text-[29px] font-semibold leading-10">Premium</h1>
              <h2 className="text-[29px] font-semibold leading-10">{title}</h2>
            </div>
            <div className="mt-4">
              <ul className="list-none pl-0 text-[16px] space-y-4">
                <li className="flex items-top pb-2">
                  <span
                    className="mr-2 mt-[0.15rem]"
                    dangerouslySetInnerHTML={{ __html: CheckmarkIcon() }}
                  />
                  Unlimited Copilot questions
                </li>
                <li className="flex items-top pb-2">
                  <span
                    className="mr-2 mt-[0.15rem]"
                    dangerouslySetInnerHTML={{ __html: CheckmarkIcon() }}
                  />
                  Deeper app personalization
                </li>
                <li className="flex items-top pb-2">
                  <span
                    className="mr-2 mt-[0.15rem]"
                    dangerouslySetInnerHTML={{ __html: CheckmarkIcon() }}
                  />
                  Direct community support from PCOS nutritionists & naturopaths
                </li>
                <li className="flex items-top pb-2">
                  <span
                    className="mr-2 mt-[0.15rem]"
                    dangerouslySetInnerHTML={{ __html: CheckmarkIcon() }}
                  />
                  Direct messaging to PCOS nutritionists & naturopaths
                </li>
                <li className="flex items-top pb-2">
                  <span
                    className="mr-2 mt-[0.15rem]"
                    dangerouslySetInnerHTML={{ __html: CheckmarkIcon() }}
                  />
                  15% off PCOS vitamins
                </li>
                <li className="flex items-top pb-2">
                  <span
                    className="mr-2 mt-[0.15rem]"
                    dangerouslySetInnerHTML={{ __html: CheckmarkIcon() }}
                  />
                  Free gifts with vitamin orders
                </li>
                <li className="flex items-top pb-2">
                  <span
                    className="mr-2 mt-[0.15rem]"
                    dangerouslySetInnerHTML={{ __html: CheckmarkIcon() }}
                  />
                  Access to exclusive PCOS education content
                </li>
              </ul>
            </div>
            <div className="flex align-center justify-center mt-8 ">
              <button
                onClick={() => checkout(selectedPlan, currentUser.id)}
                className="bg-purple-600 text-white p-3 rounded-full text-[16px] font-semibold max-w-fit"
              >
                <span className="p-3">Start 30 Day Free Trial </span>
              </button>
            </div>
          </div>
        </div>
        <div
          className="flex items-center absolute right-[0px] bg-white text-black px-8 py-1 text-[26px] rounded-xl z-30 "
          style={{
            clipPath: "polygon(0 0, 100% 0%, 100% 100%, 12% 100%)",
            border: "1px solid #f1edea",
          }}
        >
          <div className="mr-2 font-semibold">{'$0'}</div>
          <div
            className="text-[12px] text-[#858585]"
            dangerouslySetInnerHTML={{ __html: "per month" }}
          ></div>
        </div>
        <div
          className="w-[400px] top-[24px] bg-[#fff8f5] rounded-xl shadow-lg p-4 relative border border-[#f1edea] z-20 mb-10"
          style={{
            clipPath:
              "polygon(54% 0px, 59% 8%, 100% 8%, 100% 100%, 0px 100%, 0px 0px, 35% 0px)",
            border: "1px solid #f1edea",
          }}
        >
          <div
            className="bg-white rounded-xl border border-[#eae7e3] p-2 pl-4 "
            style={{
              clipPath:
                "polygon(54% 0px, 59% 8%, 100% 8%, 100% 100%, 0px 100%, 0px 0px, 35% 0px)",
              border: "1px solid #f1edea",
            }}
          >
            <div className="">
              <h1 className="text-[29px] font-semibold leading-10">Free Plan</h1>
            </div>
            <div className="mt-4">
              <ul className="list-none pl-0 text-[16px] space-y-4">
                <li className="flex items-top pb-2">
                  <span
                    className="mr-2 mt-[0.15rem]"
                    dangerouslySetInnerHTML={{ __html: CheckmarkIcon() }}
                  />
                  5 Copilot questions
                </li>
                <li className="flex items-top pb-2">
                  <span
                    className="mr-2 mt-[0.15rem]"
                    dangerouslySetInnerHTML={{ __html: CheckmarkIcon() }}
                  />
                  Community Access
                </li>
                <li className="flex items-top pb-2">
                  <span
                    className="mr-2 mt-[0.15rem]"
                    dangerouslySetInnerHTML={{ __html: CheckmarkIcon() }}
                  />
                  Community Questions
                </li>
              </ul>
            </div>
            <div className="flex align-center justify-center mt-8 ">
              <NavLink
                to="/create"
                className="bg-purple-600 text-white p-3 rounded-full text-[16px] font-semibold max-w-fit"
              >
                <span className="p-3">Try for Free</span>
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}